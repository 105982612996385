<template>
	<section class="dashboard dashboard--tasks">
		<tasks-list ref="tasksList" v-on="$listeners"></tasks-list>
	</section>
</template>
<script>
import TasksList from '@/components/tasks/List'

export default {
	name: 'Tasks',

	components: {
		TasksList
	}
}
</script>
